body {
  font-family: 'IBM Plex Sans' !important;
  font-size: 15px;
  background: #fff !important;
}
::ng-deep {
.ui-table {
.ui-table-tbody {
tr {
td {
  word-break: break-all;
}
}
}
}
}

.layout-wrapper,
.layout-main,
.layout-content {
  height: auto;
  display: flow-root;
  /* width: 100%; */
  position: unset;
}

.layout-punch-inout {
  margin-left: 0 !important;
}

.alert-danger {
  color: #721c24;
  background-color: #f8d7da;
  border: 9px solid #ffff !important;
}

::ng-deep .mat-date-range-input-start-wrapper {
  padding-left: 10px;
  padding-bottom: 4px;
}

body {
  font-family: 'IBM Plex Sans' !important;
  font-size: 18px !important;
  background: radial-gradient(#f5f5f5, #ebebeb);
  background-size: contain;
  background-attachment: fixed;
  color: #000;
}

.bg-darkblue {
  background-color: #002c4f !important;
}

.row {
  row-gap: 20px;
}

.card-header {
  height: 40px;
  display: flex;
  align-items: center;
}

.btn-white {
  background: #fff !important;
  color: #666 !important;
  box-shadow: unset !important;
  border: 1px solid #e4e4e4;
  width: 40px;
}

label {
  color: #777;
  font-size: 14px;
}

#button-addon2, input {
  height: 40px !important;
  border: 1px solid #e4e4e4 !important;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}
#button-addon3 {
	border-radius: 0px;
	height: 40px !important;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
.form-control {
  background-color: #f9f9f9 !important;
  font-size: 16px !important;
}

.main-gradient {
  background: linear-gradient(45deg, black, #1b96ff);
  color: #fff;
}

.logo {
  width: 143px;
  margin-bottom: 10px;
}

.dropdown {
  display: none;
}

.main-menu {
  display: flex;
}

.price {
  font-size: 40px;
  font-weight: 300;
}

.header-area {
  height: 93%;
  max-height: 800px;
  background-size: cover;
  background-position: center;
}

span.text-light.header-text {
  font-size: 52px;
  font-weight: 300;
}

.header-bullets {
  font-size: 20px;
  font-weight: 400;
}

.header-heading {
  font-size: 60;
  font-weight: 300;
  line-height: 1;
  margin-bottom: 20px;
}

.body-bullets {
  font-size: 20px;
  font-weight: 400;
}

.mobile-none {
  display: flex !important;
}

@media screen and (max-width: 1200px) {
  .dropdown {
    display: block;
  }
  .main-menu {
    display: none;
  }
  .header-heading {
    font-size: 36px;
    margin-left: 26px;
  }
  .header-bullets {
    font-size: 17px;
    font-weight: 400;
  }
  .header-area {
    height: fit-content !important;
    padding: 80px 0px !important;
    max-height: fit-content !important;

  li {
    margin-bottom: 13px !important;
    line-height: 1.3 !important;
  }
}
.flip-sm {
  flex-direction: column-reverse !important;
  margin-bottom: 100px;
}
.mobile-none {
  display: none !important;
}
#screens {
  width: 178% !important;
  margin-left: -41% !important;
}
#planCard {
  zoom: 80%;
}
h1 {
  font-size: 2.1rem;
}
}

#footer ::marker {
  color: #eec35c;
}

svg:not(:host).svg-inline--fa, svg:not(:root).svg-inline--fa {
  overflow: visible;
  box-sizing: content-box
}

.svg-inline--fa {
  display: var(--fa-display, inline-block);
  height: 1em;
  overflow: visible;
  vertical-align: -.125em
}

.svg-inline--fa.fa-2xs {
  vertical-align: .1em
}

.svg-inline--fa.fa-xs {
  vertical-align: 0
}

.svg-inline--fa.fa-sm {
  vertical-align: -.0714285705em
}

.svg-inline--fa.fa-lg {
  vertical-align: -.2em
}

.svg-inline--fa.fa-xl {
  vertical-align: -.25em
}

.svg-inline--fa.fa-2xl {
  vertical-align: -.3125em
}

.svg-inline--fa.fa-pull-left {
  margin-right: var(--fa-pull-margin, .3em);
  width: auto
}

.svg-inline--fa.fa-pull-right {
  margin-left: var(--fa-pull-margin, .3em);
  width: auto
}

.svg-inline--fa.fa-li {
  width: var(--fa-li-width, 2em);
  top: .25em
}

.svg-inline--fa.fa-fw {
  width: var(--fa-fw-width, 1.25em)
}

.fa-layers svg.svg-inline--fa {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0
}

.fa-layers-counter, .fa-layers-text {
  display: inline-block;
  position: absolute;
  text-align: center
}

.fa-layers {
  display: inline-block;
  height: 1em;
  position: relative;
  text-align: center;
  vertical-align: -.125em;
  width: 1em
}

.fa-layers svg.svg-inline--fa {
  -webkit-transform-origin: center center;
  transform-origin: center center
}

.fa-layers-text {
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  -webkit-transform-origin: center center;
  transform-origin: center center
}

.fa-layers-counter {
  background-color: var(--fa-counter-background-color, #ff253a);
  border-radius: var(--fa-counter-border-radius, 1em);
  box-sizing: border-box;
  color: var(--fa-inverse, #fff);
  line-height: var(--fa-counter-line-height, 1);
  max-width: var(--fa-counter-max-width, 5em);
  min-width: var(--fa-counter-min-width, 1.5em);
  overflow: hidden;
  padding: var(--fa-counter-padding, .25em .5em);
  right: var(--fa-right, 0);
  text-overflow: ellipsis;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-counter-scale, .25));
  transform: scale(var(--fa-counter-scale, .25));
  -webkit-transform-origin: top right;
  transform-origin: top right
}

.fa-layers-bottom-right {
  bottom: var(--fa-bottom, 0);
  right: var(--fa-right, 0);
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  -webkit-transform-origin: bottom right;
  transform-origin: bottom right
}

.fa-layers-bottom-left {
  bottom: var(--fa-bottom, 0);
  left: var(--fa-left, 0);
  right: auto;
  top: auto;
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  -webkit-transform-origin: bottom left;
  transform-origin: bottom left
}

.fa-layers-top-right {
  top: var(--fa-top, 0);
  right: var(--fa-right, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  -webkit-transform-origin: top right;
  transform-origin: top right
}

.fa-layers-top-left {
  left: var(--fa-left, 0);
  right: auto;
  top: var(--fa-top, 0);
  -webkit-transform: scale(var(--fa-layers-scale, .25));
  transform: scale(var(--fa-layers-scale, .25));
  -webkit-transform-origin: top left;
  transform-origin: top left
}

.fa-1x {
  font-size: 1em
}

.fa-2x {
  font-size: 2em
}

.fa-3x {
  font-size: 3em
}

.fa-4x {
  font-size: 4em
}

.fa-5x {
  font-size: 5em
}

.fa-6x {
  font-size: 6em
}

.fa-7x {
  font-size: 7em
}

.fa-8x {
  font-size: 8em
}

.fa-9x {
  font-size: 9em
}

.fa-10x {
  font-size: 10em
}

.fa-2xs {
  font-size: .625em;
  line-height: .1em;
  vertical-align: .225em
}

.fa-xs {
  font-size: .75em;
  line-height: .0833333337em;
  vertical-align: .125em
}

.fa-sm {
  font-size: .875em;
  line-height: .0714285718em;
  vertical-align: .0535714295em
}

.fa-lg {
  font-size: 1.25em;
  line-height: .05em;
  vertical-align: -.075em
}

.fa-xl {
  font-size: 1.5em;
  line-height: .0416666682em;
  vertical-align: -.125em
}

.fa-2xl {
  font-size: 2em;
  line-height: .03125em;
  vertical-align: -.1875em
}

.fa-fw {
  text-align: center;
  width: 1.25em
}

.fa-ul {
  list-style-type: none;
  margin-left: var(--fa-li-margin, 2.5em);
  padding-left: 0
}

.fa-ul > li {
  position: relative
}

.fa-li {
  left: calc(var(--fa-li-width, 2em) * -1);
  position: absolute;
  text-align: center;
  width: var(--fa-li-width, 2em);
  line-height: inherit
}

.fa-border {
  border-color: var(--fa-border-color, #eee);
  border-radius: var(--fa-border-radius, .1em);
  border-style: var(--fa-border-style, solid);
  border-width: var(--fa-border-width, .08em);
  padding: var(--fa-border-padding, .2em .25em .15em)
}

.fa-pull-left {
  float: left;
  margin-right: var(--fa-pull-margin, .3em)
}

.fa-pull-right {
  float: right;
  margin-left: var(--fa-pull-margin, .3em)
}

.fa-beat {
  -webkit-animation-name: fa-beat;
  animation-name: fa-beat;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out)
}

.fa-bounce {
  -webkit-animation-name: fa-bounce;
  animation-name: fa-bounce;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.28, .84, .42, 1))
}

.fa-fade {
  -webkit-animation-name: fa-fade;
  animation-name: fa-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1))
}

.fa-beat-fade {
  -webkit-animation-name: fa-beat-fade;
  animation-name: fa-beat-fade;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1));
  animation-timing-function: var(--fa-animation-timing, cubic-bezier(.4, 0, .6, 1))
}

.fa-flip {
  -webkit-animation-name: fa-flip;
  animation-name: fa-flip;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, ease-in-out);
  animation-timing-function: var(--fa-animation-timing, ease-in-out)
}

.fa-shake {
  -webkit-animation-name: fa-shake;
  animation-name: fa-shake;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear)
}

.fa-spin {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-delay: var(--fa-animation-delay, 0s);
  animation-delay: var(--fa-animation-delay, 0s);
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 2s);
  animation-duration: var(--fa-animation-duration, 2s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, linear);
  animation-timing-function: var(--fa-animation-timing, linear)
}

.fa-spin-reverse {
  --fa-animation-direction: reverse
}

.fa-pulse, .fa-spin-pulse {
  -webkit-animation-name: fa-spin;
  animation-name: fa-spin;
  -webkit-animation-direction: var(--fa-animation-direction, normal);
  animation-direction: var(--fa-animation-direction, normal);
  -webkit-animation-duration: var(--fa-animation-duration, 1s);
  animation-duration: var(--fa-animation-duration, 1s);
  -webkit-animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  animation-iteration-count: var(--fa-animation-iteration-count, infinite);
  -webkit-animation-timing-function: var(--fa-animation-timing, steps(8));
  animation-timing-function: var(--fa-animation-timing, steps(8))
}

@media (prefers-reduced-motion: reduce) {
  .fa-beat, .fa-beat-fade, .fa-bounce, .fa-fade, .fa-flip, .fa-pulse, .fa-shake, .fa-spin, .fa-spin-pulse {
    -webkit-animation-delay: -1ms;
    animation-delay: -1ms;
    -webkit-animation-duration: 1ms;
    animation-duration: 1ms;
    -webkit-animation-iteration-count: 1;
    animation-iteration-count: 1;
    transition-delay: 0s;
    transition-duration: 0s
  }
}

@-webkit-keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25))
  }
}

@keyframes fa-beat {
  0%, 90% {
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  45% {
    -webkit-transform: scale(var(--fa-beat-scale, 1.25));
    transform: scale(var(--fa-beat-scale, 1.25))
  }
}

@-webkit-keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0)
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em))
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0)
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em))
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
}

@keyframes fa-bounce {
  0% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
  10% {
    -webkit-transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0);
    transform: scale(var(--fa-bounce-start-scale-x, 1.1), var(--fa-bounce-start-scale-y, .9)) translateY(0)
  }
  30% {
    -webkit-transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em));
    transform: scale(var(--fa-bounce-jump-scale-x, .9), var(--fa-bounce-jump-scale-y, 1.1)) translateY(var(--fa-bounce-height, -.5em))
  }
  50% {
    -webkit-transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0);
    transform: scale(var(--fa-bounce-land-scale-x, 1.05), var(--fa-bounce-land-scale-y, .95)) translateY(0)
  }
  57% {
    -webkit-transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em));
    transform: scale(1, 1) translateY(var(--fa-bounce-rebound, -.125em))
  }
  64% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
  100% {
    -webkit-transform: scale(1, 1) translateY(0);
    transform: scale(1, 1) translateY(0)
  }
}

@-webkit-keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4)
  }
}

@keyframes fa-fade {
  50% {
    opacity: var(--fa-fade-opacity, .4)
  }
}

@-webkit-keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125))
  }
}

@keyframes fa-beat-fade {
  0%, 100% {
    opacity: var(--fa-beat-fade-opacity, .4);
    -webkit-transform: scale(1);
    transform: scale(1)
  }
  50% {
    opacity: 1;
    -webkit-transform: scale(var(--fa-beat-fade-scale, 1.125));
    transform: scale(var(--fa-beat-fade-scale, 1.125))
  }
}

@-webkit-keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg))
  }
}

@keyframes fa-flip {
  50% {
    -webkit-transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg));
    transform: rotate3d(var(--fa-flip-x, 0), var(--fa-flip-y, 1), var(--fa-flip-z, 0), var(--fa-flip-angle, -180deg))
  }
}

@-webkit-keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  24%, 8% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg)
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg)
  }
  100%, 40% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@keyframes fa-shake {
  0% {
    -webkit-transform: rotate(-15deg);
    transform: rotate(-15deg)
  }
  4% {
    -webkit-transform: rotate(15deg);
    transform: rotate(15deg)
  }
  24%, 8% {
    -webkit-transform: rotate(-18deg);
    transform: rotate(-18deg)
  }
  12%, 28% {
    -webkit-transform: rotate(18deg);
    transform: rotate(18deg)
  }
  16% {
    -webkit-transform: rotate(-22deg);
    transform: rotate(-22deg)
  }
  20% {
    -webkit-transform: rotate(22deg);
    transform: rotate(22deg)
  }
  32% {
    -webkit-transform: rotate(-12deg);
    transform: rotate(-12deg)
  }
  36% {
    -webkit-transform: rotate(12deg);
    transform: rotate(12deg)
  }
  100%, 40% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
}

@-webkit-keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0);
    transform: rotate(0)
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg)
  }
}

.fa-rotate-90 {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg)
}

.fa-rotate-180 {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg)
}

.fa-rotate-270 {
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg)
}

.fa-flip-horizontal {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1)
}

.fa-flip-vertical {
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1)
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1)
}

.fa-rotate-by {
  -webkit-transform: rotate(var(--fa-rotate-angle, none));
  transform: rotate(var(--fa-rotate-angle, none))
}

.fa-stack {
  display: inline-block;
  vertical-align: middle;
  height: 2em;
  position: relative;
  width: 2.5em
}

.fa-stack-1x, .fa-stack-2x {
  bottom: 0;
  left: 0;
  margin: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: var(--fa-stack-z-index, auto)
}

.svg-inline--fa.fa-stack-1x {
  height: 1em;
  width: 1.25em
}

.svg-inline--fa.fa-stack-2x {
  height: 2em;
  width: 2.5em
}

.fa-inverse {
  color: var(--fa-inverse, #fff)
}

.fa-sr-only, .sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.fa-sr-only-focusable:not(:focus), .sr-only-focusable:not(:focus) {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0
}

.svg-inline--fa .fa-primary {
  fill: var(--fa-primary-color, currentColor);
  opacity: var(--fa-primary-opacity, 1)
}

.svg-inline--fa .fa-secondary {
  fill: var(--fa-secondary-color, currentColor);
  opacity: var(--fa-secondary-opacity, .4)
}

.svg-inline--fa.fa-swap-opacity .fa-primary {
  opacity: var(--fa-secondary-opacity, .4)
}

.svg-inline--fa.fa-swap-opacity .fa-secondary {
  opacity: var(--fa-primary-opacity, 1)
}

.svg-inline--fa mask .fa-primary, .svg-inline--fa mask .fa-secondary {
  fill: #000
}

.fa-duotone.fa-inverse, .fad.fa-inverse {
  color: var(--fa-inverse, #fff)
}

.flipX video::-webkit-media-text-track-display {
  transform: matrix(-1, 0, 0, 1, 0, 0) !important;
}

.flipXY video::-webkit-media-text-track-display {
  transform: matrix(-1, 0, 0, -1, 0, 0) !important;
}

.flipXYX video::-webkit-media-text-track-display {
  transform: matrix(1, 0, 0, -1, 0, 0) !important;
}

@keyframes blinkWarning {
  0% {
    color: red;
  }
  100% {
    color: white;
  }
}

@-webkit-keyframes blinkWarning {
  0% {
    color: red;
  }
  100% {
    color: white;
  }
}

.blinkWarning {
  -webkit-animation: blinkWarning 1s linear infinite;
  -moz-animation: blinkWarning 1s linear infinite;
  animation: blinkWarning 1s linear infinite;
}

@font-face {
  font-weight: 400;
  font-style: normal;
  font-family: circular;
}

@font-face {
  font-weight: 700;
  font-style: normal;
  font-family: circular;
}

:host, :root {
  --fa-font-solid: normal 900 1em/1 "Font Awesome 6 Solid";
  --fa-font-regular: normal 400 1em/1 "Font Awesome 6 Regular";
  --fa-font-light: normal 300 1em/1 "Font Awesome 6 Light";
  --fa-font-thin: normal 100 1em/1 "Font Awesome 6 Thin";
  --fa-font-duotone: normal 900 1em/1 "Font Awesome 6 Duotone";
  --fa-font-sharp-solid: normal 900 1em/1 "Font Awesome 6 Sharp";
  --fa-font-brands: normal 400 1em/1 "Font Awesome 6 Brands"
}

.input-group {
  flex-wrap: nowrap;
}
th .one-liner {
  word-break: break-all !important;
  white-space: nowrap !important;
  margin-right: 5px !important;
}
:host ::ng-deep .ui-dropdown.ui-widget.ui-state-default.ui-corner-all {
  width: -webkit-fill-available;
}
:host ::ng-deep .mat-form-field-wrapper {
  flex-grow: 1;
}
.desktop-table-label {
  display: inline-flex !important;
}
@media screen and (max-width: 900px) {
  .mobile-table-label {
    display: inline-flex;
  }
  .desktop-table-label {
    display: none !important;
  }
  tr {
    display: flex;
    flex-direction: column;
    padding: 20px !important;
  }
  tr td {
    display: contents !important;
  }
  :host ::ng-deep .ui-table-thead {
    display: none !important;
  }
  .input-group {
    max-width: unset !important;
  }
  :host ::ng-deep p-paginator {
    zoom: 80%;
  }
  #table-buttons button {
    flex-grow: 1;
  }
  :host ::ng-deep #attachment .ui-dropdown.ui-widget.ui-state-default.ui-corner-all {
    width: 25px !important;
  }
  #headerText {
    font-size: 7vw !important;
    line-height: 1.2;
    font-weight: 500;
  }
  #headerSubtext {
    font-size: 4.25vw !important;
  }
}
@media screen and (min-width: 1860px) {
  #headerText{
    font-size: 70px !important;
    line-height: 1.2;
    font-weight: 500;
  }
  #headerSubtext {
    font-size: 45px !important;
  }
}
#actions {
  justify-content: space-between !important;
  display: flex;
  width: 100% !important;
}
.mobile-table-label {
  font-weight: 600;
}
th {
  width: auto !important;
}
th:first-child {
  width: 80px !important;
}
th:last-child {
  width: 64px !important;
}
th:nth-last-child(2) {
  width: 45px !important;
}
:host ::ng-deep .mat-form-field-suffix {
  display: none !important;
}
:host ::ng-deep span.ui-calendar {
  width: 100% !important;
}
:host ::ng-deep .ui-panel-titlebar {
  display: flex !important;
}
:host ::ng-deep .ng-trigger.ng-trigger-animation.ui-overlaypanel.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ng-star-inserted  .ui-overlaypanel-content {
  padding: 0px !important;
}
:host ::ng-deep .ui-paginator-bottom.ui-paginator {
  display: flex !important;
  padding-bottom: 9px !important;
  align-items: center !important;
}
:host ::ng-deep .ui-button-text {
  padding: 7px !important;
}
:host ::ng-deep #table-ppanel p-dropdown {
  height: 40px !important;
}
:host :ng-deep li.ui-dropdown-item.ui-corner-all {
  padding-top: 7px !important;
  padding-left: 7px !important;
}
:host ::ng-deep .ng-trigger.ng-trigger-overlayAnimation.ui-dropdown-panel.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ng-star-inserted {
  width: 100% !important;
}
:host ::ng-deep .ui-dialog-resizable .ui-dialog-content {
  overflow-x: hidden !important;
  overflow-y: inherit !important;
}
:host ::ng-deep .ui-dropdown {
  min-width: unset !important;
}
:host ::ng-deep .ng-trigger.ng-trigger-overlayAnimation.ui-dropdown-panel.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ng-star-inserted {
  width: 100% !important;
}
:host ::ng-deep .ng-trigger .ui-overlaypanel-content {
  padding: 0px !important;
}
:host ::ng-deep .ui-contextmenu.ui-widget.ui-widget-content.ui-corner-all.ui-shadow {
  position: fixed !important;
}
:host ::ng-deep span.ui-multiselect-filter-icon.pi.pi-search {
  display: none !important;
}
:host ::ng-deep .ng-trigger.ng-trigger-overlayAnimation.ui-multiselect-panel.ui-widget.ui-widget-content.ui-corner-all.ui-shadow.ng-star-inserted {
  width: 100% !important;
  left: 0px !important;
}
:host ::ng-deep .mat-form-field-appearance-legacy .mat-form-field-infix {
  padding: .4375em 10px !important;
}
:host ::ng-deep .input-group .ui-dropdown.ui-widget.ui-state-default.ui-corner-all {
  min-width: unset !important;
}
:host ::ng-deep .input-group .mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  width: unset !important;
}